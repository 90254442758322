<template>
    <a-layout>
        <a-layout-sider v-if="winwidth>winwidthmodelimit&&!ismobile" v-model:collapsed="collapsed" :trigger="null" collapsible class="sidemenucontainer">
            <fep-sidemenus :selectedkey="$uimenuselectedkey" v-model:collapsed="collapsed"/>
        </a-layout-sider>
        <a-drawer v-else width="60%" placement="left" :title="$langsmgr('langkey.sitename.short')" :open="drawstate" :closable="false" @close="closeDraw">
            <template #extra>
                <menu-fold-outlined class="trigger" @click="closeDraw"></menu-fold-outlined>
            </template>
            <fep-sidemenus :selectedkey="$uimenuselectedkey" :collapsed="true"/>
        </a-drawer>
        <a-layout-header class="headcontainer">
            <div class="headright">
                <span class="headmenu noselect">
                    <fep-headmenus/>
                </span>
            </div>
            <div v-if="winwidth>winwidthmodelimit&&!ismobile">
                <span :class="winwidth>winwidthmodelimit&&collapsed?'pcleftcollapsed':'pcleft'"></span>
                <menu-unfold-outlined v-if="collapsed" class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <menu-fold-outlined v-else class="trigger" @click="switchmenu"></menu-fold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
            <div v-else>
                <menu-unfold-outlined class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
        </a-layout-header>
        <a-layout-content class="acontent" :class="winwidth>winwidthmodelimit&&!ismobile?collapsed?'acontentlite':'acontentfull':null">
            <div :class="!ismobile?(winwidth>winwidthmodelimit?'vcontentlite':'vcontentfull'):'vcontentfull'">
                <a-row>
                    <a-col :span="24">
                        <a-card class="rowcard" :bordered="false" :bodyStyle="{margin:0,padding:0}" :style="{borderRadius:'2px', marginTop:0}">
                            <div class="tipsarea">
                                <a-alert :message="$langsmgr('langkey.component.flowstats.tips')" :show-icon="false" class="atips" type="info" banner/>
                            </div>
                            <a-divider :style="{margin:0}"/>
                            <a-table :key="new Date().getTime()" ref="flowstatstab" :loading="spinning_flowstats"
                                :data-source="flowstatsdata"
                                :columns="flowstatscolumns" :scroll="{x:1000}" class="tab-content" :pagination="false">
                                <template #bodyCell="{column,record}">
                                    <a-skeleton active :loading="loading_flowstats">
                                        <template v-if="column.key=='ratio'">
                                            <a-tag v-if="record.ratio!='-'" class="ratiotag">{{record.ratio}}</a-tag>
                                            <span v-else>-</span>
                                        </template>
                                        <template v-if="column.key=='rectime'">
                                            {{record.rectime}}
                                        </template>
                                        <template v-if="column.key=='actup'">
                                            {{record.actup}}
                                        </template>
                                        <template v-if="column.key=='actdown'">
                                            {{record.actdown}}
                                        </template>
                                        <template v-if="column.key=='total'">
                                            {{record.total}}
                                        </template>
                                    </a-skeleton>
                                </template>
                            </a-table>
                        </a-card>
                    </a-col>
                </a-row>
                <Cntfooter/>
            </div>
        </a-layout-content>
    </a-layout>
</template>
<script setup>
import {MenuFoldOutlined,MenuUnfoldOutlined,CaretDownOutlined} from '@ant-design/icons-vue'
import { ref,getCurrentInstance, onMounted,nextTick } from 'vue'
import Cntfooter from './cntfooter.vue'
import axios from 'axios'

const spinning_flowstats = ref(false)

const currentviewlankey = ref('langkey.menuitem.flowstats')
const winwidthmodelimit = 1320
const drawstate = ref(false)
const winwidth = ref(0)
const flowstatstab = ref()

const ginstance = getCurrentInstance()
const gconfig = ginstance.appContext.config.globalProperties;
const collapsed = ref(gconfig.$uimenucollapsed)
const ismobile = ref(gconfig.$ismobile())

const loading_flowstats = ref(true)

const flowstatscolumns = ref([
    {title:gconfig.$langsmgr('langkey.component.flowstats.column.recordtime'), width:255, dataIndex:'rectime',key:'rectime'},
    {title:gconfig.$langsmgr('langkey.component.flowstats.column.uploads'), dataIndex:'actup',key:'actup'},
    {title:gconfig.$langsmgr('langkey.component.flowstats.column.downloads'), dataIndex:'actdown',key:'actdown'},
    {title:gconfig.$langsmgr('langkey.component.flowstats.column.ratio'), dataIndex:'ratio',key:'ratio'},
    {title:gconfig.$langsmgr('langkey.component.flowstats.column.total'), width: 120, dataIndex:'total',key:'total',fixed:'right',align:'right'},
])
const flowstatsdata = ref([
   {rectime:'-',actup:'-',actdown:'-',ratio:'-', total:'-'}
])


onMounted(()=>{
    whenWindowResize()
    window.addEventListener('resize', whenWindowResize)

    loading_flowstats.value = true
    flowstatsdata.value.splice(0x00, flowstatsdata.value.length)

    axios.get(`${gconfig.$backendbase}/flowctl/v3/getflowdatarecords`,{
        params: { r:Math.random() },
        headers: gconfig.$getauthheaders()
    }).then(resp=>{
        if(resp.data.data.result) {
            for(var i = 0x00; i < resp.data.data.records.length; i++){
                var __flowstats = resp.data.data.records[i]
                flowstatsdata.value.push({
                    rectime: __flowstats.date,
                    actup: __flowstats.input > 0 ? `${__flowstats.input.toFixed(0x03)} GB` : '-',
                    actdown: __flowstats.output > 0 ? `${__flowstats.output.toFixed(0x03)} GB` : '-',
                    ratio: __flowstats.total > 0 ? '1.00 x' : '-',
                    total: __flowstats.total > 0 ? `${__flowstats.total.toFixed(0x03)} GB` : '-'
                })
            }
        }
        loading_flowstats.value = false
    }).catch(err=>{
        gconfig.$axiosErrorHandle(err)
    })
})
const whenWindowResize=()=>{ winwidth.value = window.innerWidth }
const switchmenu = () => {
    if(!ismobile.value&&winwidth.value>winwidthmodelimit) gconfig.$uimenucollapsed = collapsed.value = !collapsed.value
    else showDraw()
}
const showDraw = () => { drawstate.value = true }
const closeDraw = () => { drawstate.value = false }

</script>

<style src="./../assets/common.css" scoped></style>
<style scoped>

.tipsarea{
    padding:20px 15px;
}

.atips{
    font-size: 16px;
    color:#1f4b74;
}
.ratiotag{
    background-color:#f0f0f0;
    color:#666666;
    width:60px;
    text-align: center;
}
</style>