<template>
    <a-layout>
        <a-layout-sider v-if="winwidth>winwidthmodelimit&&!ismobile" v-model:collapsed="collapsed" :trigger="null" collapsible class="sidemenucontainer">
            <fep-sidemenus :selectedkey="$uimenuselectedkey" v-model:collapsed="collapsed"/>
        </a-layout-sider>
        <a-drawer v-else width="60%" placement="left" :title="$langsmgr('langkey.sitename.short')" :open="drawstate" :closable="false" @close="closeDraw">
            <template #extra>
                <menu-fold-outlined class="trigger" @click="closeDraw"></menu-fold-outlined>
            </template>
            <fep-sidemenus :selectedkey="$uimenuselectedkey" :collapsed="true"/>
        </a-drawer>
        <a-layout-header class="headcontainer">
            <div class="headright">
                <span class="headmenu noselect">
                    <fep-headmenus/>
                </span>
            </div>
            <div v-if="winwidth>winwidthmodelimit&&!ismobile">
                <span :class="winwidth>winwidthmodelimit&&collapsed?'pcleftcollapsed':'pcleft'"></span>
                <menu-unfold-outlined v-if="collapsed" class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <menu-fold-outlined v-else class="trigger" @click="switchmenu"></menu-fold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
            <div v-else>
                <menu-unfold-outlined class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
        </a-layout-header>
        <a-layout-content class="acontent" :class="winwidth>winwidthmodelimit&&!ismobile?collapsed?'acontentlite':'acontentfull':null">
            <div :class="!ismobile?(winwidth>winwidthmodelimit?'vcontentlite':'vcontentfull'):'vcontentfull'">
                <a-row>
                    <a-col :span="24">
                        <a-card class="rowcard" :style="{borderRadius:'2px',marginTop:0}" :bodyStyle="{padding:'20px'}" :bordered="false">
                                <div class="profilecard">
                                    {{$langsmgr('langkey.component.profile.text.mywallet')}}
                                    <div class="profileicon"><WalletFilled/></div>
                                </div>
                            <a-skeleton active :loading="loading_wallet" :style="{paddingTop:'20px'}">
                                <div class="wallet">
                                    <span class="balance">{{userwalletbalance}}</span>
                                    <span class="coinunit">CNY</span>
                                </div>
                                <div class="surplustext">
                                    {{$langsmgr('langkey.component.profile.text.balance')}}
                                </div>
                            </a-skeleton>
                        </a-card>
                        
                        <a-card class="rowcard" :title="$langsmgr('langkey.component.profile.title.repasswd')" :style="{borderRadius:'2px',marginTop:0}" :bodyStyle="{padding:'20px 20px 25px 20px'}" :bordered="false"
                             :headStyle="{backgroundColor:'#f8f9fc'}">
                            <div>
                                <div class="passwdlinetitle">{{$langsmgr('langkey.component.profile.text.oldpasswd')}}</div>
                                <div><a-input-password v-model:value="refval_oldpasswd" :style="{width:ismobile?'100%':'400px'}" :placeholder="$langsmgr('langkey.component.profile.text.oldpasswd.holder')" /></div>
                            </div>
                            <div>
                                <div class="passwdlinetitle">{{$langsmgr('langkey.component.profile.text.newpasswd')}}</div>
                                <div><a-input-password v-model:value="refval_newpasswd" :style="{width:ismobile?'100%':'400px'}" :placeholder="$langsmgr('langkey.component.profile.text.newpasswd.holder')"/></div>
                            </div>
                            <div>
                                <div class="passwdlinetitle">{{$langsmgr('langkey.component.profile.text.cfmpasswd')}}</div>
                                <div><a-input-password v-model:value="refval_verifypasswd" :style="{width:ismobile?'100%':'400px'}" :placeholder="$langsmgr('langkey.component.profile.text.cfmpasswd.holder')"/></div>
                            </div>
                            <div :style="{padding:'20px 0 0 0'}">
                                <a-button type="primary" :loading="loading_changepasswd" @click="changepasswd">{{$langsmgr('langkey.component.profile.text.repasswd.save')}}</a-button>
                            </div>
                        </a-card>
                        <a-card class="rowcard" :title="$langsmgr('langkey.component.profile.text.tggroup')" :style="{borderRadius:'2px',marginTop:0}" :bodyStyle="{padding:'20px 20px 25px 20px'}" :bordered="false"
                             :headStyle="{backgroundColor:'#f8f9fc',paddingRight:'10px'}">
                            <template #extra>
                                <a-button type="primary" @click="jointggroup">{{$langsmgr('langkey.component.profile.text.tggroup.join')}}</a-button>
                            </template>
                        </a-card>
                        <a-card class="rowcard" :title="$langsmgr('langkey.component.profile.title.resetsubscr')" :style="{borderRadius:'2px',marginTop:0}" :bodyStyle="{padding:'20px 20px 25px 20px'}" :bordered="false"
                             :headStyle="{backgroundColor:'#f8f9fc',paddingRight:'10px'}">
                            <div>
                            <a-alert :message="$langsmgr('langkey.component.profile.text.resettip')" :show-icon="false" class="atips" banner/>
                            </div>
                            <div :style="{paddingTop:'20px'}">
                                <a-button type="primary" :loading="loading_resetsubscribe" danger @click="resetsubscribeConfirm">{{$langsmgr('langkey.component.profile.text.resetsubmit')}}</a-button>
                            </div>
                        </a-card>
                    </a-col>
                </a-row>
                <Cntfooter/>
            </div>
            <contextHolder/>
        </a-layout-content>
    </a-layout>
</template>
<script setup>
import {MenuFoldOutlined,MenuUnfoldOutlined,CaretDownOutlined,WalletFilled,ExclamationCircleOutlined} from '@ant-design/icons-vue'
import { ref,getCurrentInstance, onMounted,h } from 'vue'
import Cntfooter from './cntfooter.vue'
import { Modal } from 'ant-design-vue'
import axios from 'axios'
import { notification,message } from 'ant-design-vue'

const [modal, contextHolder] = Modal.useModal();

const loading_wallet = ref(true)
const loading_resetsubscribe = ref(false)

const currentviewlankey = ref('langkey.menuitem.profile')
const winwidthmodelimit = 1320
const drawstate = ref(false)
const winwidth = ref(0)

const ginstance = getCurrentInstance()
const gconfig = ginstance.appContext.config.globalProperties;
const collapsed = ref(gconfig.$uimenucollapsed)
const ismobile = ref(gconfig.$ismobile())

const userwalletbalance = ref(0)
const loading_changepasswd = ref(false)
const refval_oldpasswd = ref('')
const refval_newpasswd = ref('')
const refval_verifypasswd = ref('')

onMounted(()=>{
    whenWindowResize()
    window.addEventListener('resize', whenWindowResize)

    axios.get(`${gconfig.$backendbase}/usrctl/v3/getcurrentuserinf`, {
        headers: gconfig.$getauthheaders()
    }).then((response)=>{
        //console.log(response)
        if(response.data.data.result) {
            userwalletbalance.value = response.data.data.userinf.blanace.toFixed(0x02)
        }
        loading_wallet.value = false
    }).catch((error)=>{
        gconfig.$axiosErrorHandle(error)
    })
})
const whenWindowResize=()=>{ winwidth.value = window.innerWidth }
const switchmenu = () => {
    if(!ismobile.value&&winwidth.value>winwidthmodelimit) gconfig.$uimenucollapsed = collapsed.value = !collapsed.value
    else showDraw()
}
const showDraw = () => { drawstate.value = true }
const closeDraw = () => { drawstate.value = false }

const resetsubscribeConfirm = () => {
    modal.confirm({
        title: gconfig.$langsmgr('langkey.component.profile.msg.title'),
        icon: h(ExclamationCircleOutlined),
        content: gconfig.$langsmgr('langkey.component.profile.msg.content'),
        okText: gconfig.$langsmgr('langkey.component.profile.msg.ok'),
        okType: "primary danger",
        cancelText: gconfig.$langsmgr('langkey.component.profile.msg.cancel'),
        onOk(){
            resetsubscribe()
        }
    })
}
const resetsubscribe = () => {
    loading_resetsubscribe.value = true
    axios.get(`${gconfig.$backendbase}/usrctl/v3/resetsubscribe`,{
        params: { r: Math.random() },
        headers: gconfig.$getauthheaders()
    }).then(response=>{
        console.log(response)
        if(response.data.data) {
            message.success(gconfig.$langsmgr('langkey.component.profile.msg.success'))
        }else{
            message.error(gconfig.$langsmgr('langkey.component.profile.msg.fail'))
        }
        loading_resetsubscribe.value = false
    }).catch(error=>{
        gconfig.$axiosErrorHandle(error)
    })
}

const jointggroup = () => {
    window.open(gconfig.$telegramgroup,'_blank')
}

const changepasswd = () => {
    if(!refval_oldpasswd.value || refval_oldpasswd.value.length == 0x00) {
        message.error(gconfig.$langsmgr('langkey.component.profile.alert.oldpasswdempty'))
        return
    }
    if(!refval_newpasswd.value || refval_newpasswd.value.length == 0x00) {
        message.error(gconfig.$langsmgr('langkey.component.profile.alert.newpasswdempty'))
        return
    }
    if(refval_newpasswd.value != refval_verifypasswd.value){
        message.error(gconfig.$langsmgr('langkey.component.profile.alert.passwddifferent'))
        return
    }

    loading_changepasswd.value = true

    axios({
        url: `${gconfig.$backendbase}/usrctl/v3/changepasswd`,
        method: 'get',
        params: {
            oldpasswd: refval_oldpasswd.value,
            newpasswd: refval_newpasswd.value
        },
        headers: gconfig.$getauthheaders(),
        globalconfig: gconfig
    }).then((response) => {
        console.log(response)
        if(response.data.data.result){
            notification['info']({
                message: gconfig.$langsmgr('langkey.component.profile.notify.msg'),
                description: gconfig.$langsmgr('langkey.component.profile.notify.desc'),
                duration: 3
            })
            response.config.globalconfig.$vusername = ""
            response.config.globalconfig.$vtoken = ""
            response.config.globalconfig.$storage.remove(response.config.globalconfig.$storagekey_fep_vsignname)
            response.config.globalconfig.$storage.remove(response.config.globalconfig.$storagekey_fep_vtoken)
            location.href='#/'
        }else{
            switch(response.data.data.code){
                case "rsc/20006":
                    notification['error']({
                        message: gconfig.$langsmgr('langkey.component.profile.notify.error.msg'),
                        description: gconfig.$langsmgr('langkey.component.profile.notify.error.desc'),
                        duration: 3
                    })
                    break
            }
        }
        loading_changepasswd.value = false
    }).catch((error)=>{
        gconfig.$axiosErrorHandle(error)
    })

    // notification['error']({
    //     message: gconfig.$langsmgr("langkey.component.login.result.failed.message"),
    //     description: gconfig.$langsmgr("langkey.component.login.result.failed.unknowusernameorpassworderror"),
    //     duration: 3
    // })
}

</script>

<style src="./../assets/common.css" scoped></style>
<style scoped>

.profilecard{
    position: relative;
    width:100%;
    font-size: 16px;
    color:#666666;
}
.profileicon{
    position: absolute;
    right:-5px;
    top:-10px;
    font-size: 40px;
    color:#cccccc;
}
.wallet{
    padding-top:20px;
}
.balance{
    font-size:60px;
    font-weight: 100;
}
.coinunit{
    padding-left:30px;
    font-size: 20px;
    font-weight: 100;
    color:#999999;
}
.surplustext{
    font-size: 16px;
    color:#666666;
}
.textright{
    text-align: right;
}
.walletopts{
    padding:15px 0 10px 0;
}
.pl10px{
    padding-left:10px;
}

.passwdlinetitle{
    font-size:16px;
    padding:5px 0 15px 0;
}
.atips{
    font-size: 16px;
    color:#855c0d;
}
</style>