<template>
    <a-layout>
        <a-layout-sider v-if="winwidth>winwidthmodelimit&&!ismobile" v-model:collapsed="collapsed" :trigger="null" collapsible class="sidemenucontainer">
            <fep-sidemenus :selectedkey="$uimenuselectedkey" v-model:collapsed="collapsed"/>
        </a-layout-sider>
        <a-drawer v-else width="60%" placement="left" :title="$langsmgr('langkey.sitename.short')" :open="drawstate" :closable="false" @close="closeDraw">
            <template #extra>
                <menu-fold-outlined class="trigger" @click="closeDraw"></menu-fold-outlined>
            </template>
            <fep-sidemenus :selectedkey="$uimenuselectedkey" :collapsed="true"/>
        </a-drawer>
        <a-layout-header class="headcontainer">
            <div class="headright">
                <span class="headmenu noselect">
                    <fep-headmenus/>
                </span>
            </div>
            <div v-if="winwidth>winwidthmodelimit&&!ismobile">
                <span :class="winwidth>winwidthmodelimit&&collapsed?'pcleftcollapsed':'pcleft'"></span>
                <menu-unfold-outlined v-if="collapsed" class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <menu-fold-outlined v-else class="trigger" @click="switchmenu"></menu-fold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
            <div v-else>
                <menu-unfold-outlined class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
        </a-layout-header>
        <a-layout-content class="acontent" :class="winwidth>winwidthmodelimit&&!ismobile?collapsed?'acontentlite':'acontentfull':null">
            <div :class="!ismobile?(winwidth>winwidthmodelimit?'vcontentlite':'vcontentfull'):'vcontentfull'">
                <a-row>
                    <a-col :span="24">
                        <a-card v-if="!ismobile" class="rowcard" :bordered="false" :bodyStyle="{margin:0,padding:0}" :style="{borderRadius:'2px'}">
                            <a-table :columns="billingscolumns" :data-source="billingsdata" :scroll="{x:1000}" class="tab-content" :pagination="false">
                                <template #bodyCell="{column,record}">
                                    <a-skeleton active :loading="loading_billings">
                                        <template v-if="column.key=='billno'">
                                            <a href="javascript:;" @click="viewbilling(record)">{{record.billno}}</a>
                                        </template>
                                        <template v-if="column.key=='circle'">
                                            <a-tag class="ratiotag">{{record.circle}}</a-tag>
                                        </template>
                                        <template v-if="column.key=='state'">
                                            <a-badge :color="record.stacolor"/>
                                            <span>{{record.statext}}</span>
                                        </template>
                                        <template v-if="column.key=='opts'">
                                            <a v-if="record.state==0x00||record.state==0x01" href="javascript:;" @click="viewbilling(record)">{{$langsmgr('langkey.component.billings.viewdetail')}}</a>
                                            <span v-else class="unopts">{{$langsmgr('langkey.component.billings.viewdetail')}}</span>
                                            <a-divider v-if="record.canunsubscribe&&record.state==0x01" type="vertical" :style="{backgroundColor:'#cccccc',height:'14px'}"/>
                                            <a v-if="record.canunsubscribe&&record.state==0x01" href="javascript:;" @click="unsubscribe(record)">{{$langsmgr('langkey.component.billings.unsubscribe')}}</a>
                                            <a-divider type="vertical" :style="{backgroundColor:'#cccccc',height:'14px'}"/>
                                            <a v-if="record.state==0x00" href="javascript:;" @click="closebilling(record)">{{$langsmgr('langkey.component.billings.closebill')}}</a>
                                            <span v-else class="unopts">{{$langsmgr('langkey.component.billings.closebill')}}</span>
                                        </template>
                                        <template v-if="column.key=='createtime'">
                                            <span>{{record.createtime}}</span>
                                        </template>
                                        <template v-if="column.key=='amount'">
                                            <span>￥{{record.amount}}</span>
                                        </template>
                                    </a-skeleton>
                                </template>
                            </a-table>
                        </a-card>
                        <div v-else>
                            <a-skeleton active :loading="loading_billings" :style="{padding:'20px'}">
                                <div v-if="billingsdata.length>0x00" v-for="billing in billingsdata" class="mdataitem" @click="viewbilling(billing)">
                                    <div class="mleftintro">
                                        <a-row>
                                            <a-col :span="18">
                                                <span class="cellleft mpkgname">{{billing.packname}}</span>
                                            </a-col>
                                            <a-col :span="6">
                                                <span class="cellright mpkgname">￥{{billing.amount}}</span>
                                            </a-col>
                                            <a-col :span="18">
                                                <span class="cellleft msettletime">{{billing.createtime}}</span>
                                            </a-col>
                                            <a-col :span="6">
                                                <span class="cellright msettletime">
                                                    <a-badge :color="billing.stacolor"/>
                                                    <span>{{billing.statext}}</span>
                                                </span>
                                            </a-col>
                                        </a-row>
                                        <div class="clearfixed"/>
                                    </div>
                                    <div class="mrightdetail">
                                        <RightOutlined/>
                                    </div>
                                    <div class="clearfixed"/>
                                </div>
                                <div v-else>
                                    <a-empty :image="EmptyIcon"/>
                                </div>
                            </a-skeleton>
                        </div>
                    </a-col>
                </a-row>
                <Cntfooter/>
            </div>
            <contextHolder/>
        </a-layout-content>
    </a-layout>
</template>
<script setup>
import {MenuFoldOutlined,MenuUnfoldOutlined,CaretDownOutlined,RightOutlined,ExclamationCircleOutlined} from '@ant-design/icons-vue'
import { Modal,Empty,message } from 'ant-design-vue';
import { ref,getCurrentInstance, onMounted } from 'vue'
import Cntfooter from './cntfooter.vue'
import axios from 'axios'

const EmptyIcon = Empty.PRESENTED_IMAGE_SIMPLE

const loading_billings = ref(true)

const currentviewlankey = ref('langkey.menuitem.billings')
const winwidthmodelimit = 1320
const drawstate = ref(false)
const winwidth = ref(0)

const ginstance = getCurrentInstance()
const gconfig = ginstance.appContext.config.globalProperties;
const collapsed = ref(gconfig.$uimenucollapsed)
const ismobile = ref(gconfig.$ismobile())

const billingscolumns = ref([
    {title:`#${gconfig.$langsmgr('langkey.component.billings.billno')}`, width:380, dataIndex:'billno',key:'billno',fixed:'left'},
    {title:gconfig.$langsmgr('langkey.component.billings.circle'), dataIndex:'circle',key:'circle'},
    {title:gconfig.$langsmgr('langkey.component.billings.amount'), dataIndex:'amount',key:'amount'},
    {title:gconfig.$langsmgr('langkey.component.billings.state'), dataIndex:'state',key:'state'},
    {title:gconfig.$langsmgr('langkey.component.billings.createtime'), width: 200, dataIndex:'createtime',key:'createtime'},
    {title:gconfig.$langsmgr('langkey.component.billings.operations'), width:150, dataIndex:'opts',key:'opts',fixed:'right'},
])
const billingsdata = ref([
     {billno:'-',circle:'-',amount:'-',state:'-', createtime:'-',opts:'-'},
])

onMounted(()=>{
    whenWindowResize()
    window.addEventListener('resize', whenWindowResize)

    axios.get(`${gconfig.$backendbase}/billing/v3/getbillings`,{
        params: { r:Math.random() },
        headers: gconfig.$getauthheaders()
    }).then((response)=>{
        //console.log(response)
        if(response.data.data.result){
            billingsdata.value.splice(0x00, billingsdata.value.length)
            for(var i = 0x00; i < response.data.data.billings.length; i++) {
                var __billing = response.data.data.billings[i]
                billingsdata.value.push({
                    billid: __billing.bid,
                    billno: __billing.tradeno,
                    packid: __billing.packid,
                    planid: __billing.planid,
                    packname: decodeURIComponent(__billing.packname),
                    circle: __billing.duration,
                    amount: __billing.amount,
                    state: __billing.state,
                    canunsubscribe: __billing.canunsubscribe,
                    stacolor: __billing.state == 0x00 ? "gray" :
                        __billing.state == 0x01 ? "green" : "red",
                    statext: __billing.state == 0x00 ? gconfig.$langsmgr('langkey.component.billings.state.unpaid') :
                        __billing.state == 0x01 ? gconfig.$langsmgr('langkey.component.billings.state.paid') : gconfig.$langsmgr('langkey.component.billings.state.closed'),
                    createtime: __billing.regtime,
                    opts: '-'
                })
            }
        }
        loading_billings.value = false
    }).catch((error)=>{
        gconfig.$axiosErrorHandle(error)
    })

})
const unsubscribe = (billing) => {
    Modal.confirm({
            title: gconfig.$langsmgr('langkey.component.billings.msg.title'),
            content: gconfig.$langsmgr('langkey.component.billings.uns.content'),
            okText: gconfig.$langsmgr('langkey.component.billings.msg.ok'),
            cancelText: gconfig.$langsmgr('langkey.component.billings.msg.cancel'),
            onOk() {
                axios.get(`${gconfig.$backendbase}/billing/v3/unsubscribe`,{
                    params: {
                        bid: billing.billno,
                        r: Math.random()
                    },
                    headers: gconfig.$getauthheaders()
                }).then((response)=>{
                    if(response.data.data){
                        billing.state = 0x02
                        message.success(gconfig.$langsmgr('langkey.component.billings.uns.succ'))
                    }else{
                        message.error(gconfig.$langsmgr('langkey.component.billings.uns.fail'))
                    }
                }).catch((error)=>{
                    gconfig.$axiosErrorHandle(error)
                })
            }
        })
}
const whenWindowResize=()=>{ winwidth.value = window.innerWidth }
const switchmenu = () => {
    if(!ismobile.value&&winwidth.value>winwidthmodelimit) gconfig.$uimenucollapsed = collapsed.value = !collapsed.value
    else showDraw()
}
const showDraw = () => { drawstate.value = true }
const closeDraw = () => { drawstate.value = false }

const viewbilling = (billing) => {
    if(billing.state==0x00&&!ismobile.value)
        location.href=`#/plan?bn=${billing.billno}&p=${billing.packid}&s=${billing.planid}`
    else location.href=`#/order?bn=${billing.billno}`
}
const closebilling = (billing) => {
    Modal.confirm({
        title: gconfig.$langsmgr('langkey.component.billings.msg.title'),
        content: gconfig.$langsmgr('langkey.component.billings.msg.content'),
        okText: gconfig.$langsmgr('langkey.component.billings.msg.ok'),
        cancelText: gconfig.$langsmgr('langkey.component.billings.msg.cancel'),
        onOk() {
            axios.get(`${gconfig.$backendbase}/billing/v3/closebilling`,{
                params: {
                    bid: billing.billno,
                    r: Math.random()
                },
                headers: gconfig.$getauthheaders()
            }).then((response)=>{
                if(response.data.data){
                    billing.state = 0x02
                    message.success(gconfig.$langsmgr('langkey.component.billings.msg.succ'))
                }else{
                    message.error(gconfig.$langsmgr('langkey.component.billings.msg.fail'))
                }
            }).catch((error)=>{
                gconfig.$axiosErrorHandle(error)
            })
        }
    })
}


</script>

<style src="./../assets/common.css" scoped></style>

<style scoped>
.clearfixed{
    clear:both;
}
.tab-content{
    width:calc(100%-300);
    flex-grow:1;
}
.ratiotag{
    background-color:#f0f0f0;
    color:#666666;
    width:60px;
    text-align: center;
}
.mleftintro{
    margin:0;
    padding:10px 30px 20px 20px;
    border-bottom: solid 1px #f0f0f0;
}
.mrightdetail{
    position: absolute;
    right:0;
    top:0;
    padding:30px 8px 0 0;
    color:#999999;
}
.mdataitem{
    position:relative;
    background-color: white;
    width:100%;
    overflow: hidden;
}
.cellleft{
    text-align: left;
    float:left;
}
.cellright{
    text-align: right;
    float:right;
}
.mpkgname{
    font-size:16px;
    line-height:30px;
}
.msettle{
    font-size:14px;
    line-height:20px;
    color:#999999;
}
.msettletime{
    color:#999999;
    padding:5px 0 0 0;
}
.unopts{
    color:#cccccc;
}
</style>