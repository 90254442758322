<template>
    <a-layout>
        <a-layout-sider v-if="winwidth>winwidthmodelimit&&!ismobile" v-model:collapsed="collapsed" :trigger="null" collapsible class="sidemenucontainer">
            <fep-sidemenus :selectedkey="$uimenuselectedkey" v-model:collapsed="collapsed"/>
        </a-layout-sider>
        <a-drawer v-else width="60%" placement="left" :title="$langsmgr('langkey.sitename.short')" :open="drawstate" :closable="false" @close="closeDraw">
            <template #extra>
                <menu-fold-outlined class="trigger" @click="closeDraw"></menu-fold-outlined>
            </template>
            <fep-sidemenus :selectedkey="$uimenuselectedkey" :collapsed="true"/>
        </a-drawer>
        <a-layout-header class="headcontainer">
            <div class="headright">
                <span class="headmenu noselect">
                    <fep-headmenus/>
                </span>
            </div>
            <div v-if="winwidth>winwidthmodelimit&&!ismobile">
                <span :class="winwidth>winwidthmodelimit&&collapsed?'pcleftcollapsed':'pcleft'"></span>
                <menu-unfold-outlined v-if="collapsed" class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <menu-fold-outlined v-else class="trigger" @click="switchmenu"></menu-fold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
            <div v-else>
                <menu-unfold-outlined class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
        </a-layout-header>
        <a-layout-content class="acontent" :class="winwidth>winwidthmodelimit&&!ismobile?collapsed?'acontentlite':'acontentfull':null">
            <div :class="!ismobile?(winwidth>winwidthmodelimit?'vcontentlite':'vcontentfull'):'vcontentfull'">
                <a-row>
                    <a-col :span="24">
                        <a-card class="rowcard" :bordered="false" :bodyStyle="{margin:0,padding:0}" :title="$langsmgr('langkey.component.tickets.title.recordshistory')" :style="{borderRadius:'2px',marginTop:0}"
                            :headStyle="{paddingRight:'20px'}">
                            <template #extra>
                                <a-button type="primary" @click="showNewticketModal">{{$langsmgr('langkey.component.tickets.text.newticket')}}</a-button>
                            </template>
                            <a-table :columns="ticketscolumns" :data-source="ticketsdata" :scroll="{x:1000}" class="tab-content" :pagination="false">
                                    <template #bodyCell="{column,record}">
                                        <a-skeleton active :loading="loading_tickets">
                                            <template v-if="column.key=='tickno'">
                                                <a href="javascript:;" @click="viewticket">{{record.tickno}}</a>
                                            </template>
                                            <template v-if="column.key=='createtime'">
                                                {{record.createtime}}
                                            </template>
                                            <template v-if="column.key=='lasttime'">
                                                {{record.lasttime}}
                                            </template>
                                            <template v-if="column.key=='title'">
                                                {{record.title}}
                                            </template>
                                            <template v-if="column.key=='state'">
                                                <a-badge :color="record.stacolor"/>
                                                <span>{{record.statext}}</span>
                                            </template>
                                            <template v-if="column.key=='opts'">
                                                <a href="javascript:;" @click="viewticket(record)">{{$langsmgr('langkey.component.tickets.text.viewdetail')}}</a>
                                                <a-divider type="vertical" :style="{backgroundColor:'#cccccc',height:'14px'}"/>
                                                <a v-if="record.state==0x00||record.state==0x01" href="javascript:;" @click="closeticket(record)">{{$langsmgr('langkey.component.tickets.text.close')}}</a>
                                                <span v-else class="noopts">{{$langsmgr('langkey.component.tickets.text.close')}}</span>
                                            </template>
                                        </a-skeleton>
                                    </template>
                            </a-table>
                        </a-card>
                    </a-col>
                </a-row>
                <Cntfooter/>
            </div>
            <div>
                <a-modal v-model:open="newticketshowstate" :title="$langsmgr('langkey.component.tickets.text.newticket')" :bodyStyle="{padding:'0 0 20px 0'}">
                    <template #footer>
                        <a-button @click="closeNewticketModal">{{$langsmgr('langkey.component.tickets.text.cancel')}}</a-button>
                        <a-button type="primary" :loading="loading_submitstate" @click="submitNewticket">{{$langsmgr('langkey.component.tickets.text.submit')}}</a-button>
                    </template>
                    <div>
                        <div class="feildtitle">{{$langsmgr('langkey.component.tickets.text.intro')}}</div>
                        <div><a-input v-model:value="refval_title" style="width:100%" :placeholder="$langsmgr('langkey.component.tickets.text.intro.holder')" /></div>
                    </div>
                    <div>
                        <div class="feildtitle">{{$langsmgr('langkey.component.tickets.text.msg')}}</div>
                        <div>
                            <a-textarea v-model:value="refval_content" :placeholder="$langsmgr('langkey.component.tickets.text.msg.holder')" :style="{height:'160px'}"></a-textarea>
                        </div>
                    </div>
                </a-modal>
            </div>
            <contextHolder/>
        </a-layout-content>
    </a-layout>
</template>
<script setup>
import {MenuFoldOutlined,MenuUnfoldOutlined,CaretDownOutlined,ExclamationCircleOutlined} from '@ant-design/icons-vue'
import { ref,getCurrentInstance, onMounted,h } from 'vue'
import { Modal,message } from 'ant-design-vue'
import Cntfooter from './cntfooter.vue'
import axios from 'axios'

const [modal, contextHolder] = Modal.useModal()
const currentviewlankey = ref('langkey.menuitem.tickets')
const winwidthmodelimit = 1320
const drawstate = ref(false)
const winwidth = ref(0)
const newticketshowstate = ref(false)
const loading_tickets = ref(true)
const loading_submitstate = ref(false)
const refval_title = ref('')
const refval_content = ref('')

const ginstance = getCurrentInstance()
const gconfig = ginstance.appContext.config.globalProperties
const collapsed = ref(gconfig.$uimenucollapsed)
const ismobile = ref(gconfig.$ismobile())


const ticketscolumns = ref([
    {title:gconfig.$langsmgr('langkey.component.tickets.column.intro'), width:255, dataIndex:'title',key:'title'},
    {title:gconfig.$langsmgr('langkey.component.tickets.column.state'), dataIndex:'state',key:'state'},
    {title:gconfig.$langsmgr('langkey.component.tickets.column.createtime'), dataIndex:'createtime',key:'createtime'},
    {title:gconfig.$langsmgr('langkey.component.tickets.column.lastupdate'), dataIndex:'lasttime',key:'lasttime'},
    {title:gconfig.$langsmgr('langkey.component.tickets.column.opts'), width: 120, dataIndex:'opts',key:'opts',fixed:'right',align:'right'},
])
const ticketsdata = ref([
    {tickno:'-',title:'-',state:'-', createtime:'-', lasttime:'-',opts:'-'},
])

onMounted(() => {
    whenWindowResize()
    window.addEventListener('resize', whenWindowResize)

    loadticketsdata()
})
const whenWindowResize=()=>{ winwidth.value = window.innerWidth }
const switchmenu = () => {
    if(!ismobile.value&&winwidth.value>winwidthmodelimit) ginstance.appContext.config.globalProperties.$uimenucollapsed = collapsed.value = !collapsed.value
    else showDraw()
}
const showDraw = () => { drawstate.value = true }
const closeDraw = () => { drawstate.value = false }

const viewticket = (ticket) => {
    if(!ismobile.value)window.open(`#/ticket?t=${ticket.tickno}&r=${Math.random()}`, "window","width=800px,height=600px, modal=yes,status=no")
    else location.href=`#/ticket?t=${ticket.tickno}&r=${Math.random()}`
}
const showNewticketModal = () => {
    newticketshowstate.value = true
}
const closeNewticketModal = () => {
    newticketshowstate.value = false
}
const submitNewticket = () => {
    if(!refval_title.value || !refval_content.value || refval_title.value.length == 0x00 || refval_content.value.length == 0x00) {
        message.error(gconfig.$langsmgr('langkey.component.tickets.msg.error'))
        return
    }
    loading_submitstate.value = true
    axios.post(`${gconfig.$backendbase}/order/v3/postorder`,{
        title: encodeURIComponent(refval_title.value),
        content: encodeURIComponent(refval_content.value)
    },{
        headers: gconfig.$getauthheaders()
    }).then(resp=>{
        //console.log(resp)
        if(resp.data.data.result){
            closeNewticketModal()
            refval_title.value = null
            refval_content.value = null
            message.info(gconfig.$langsmgr('langkey.component.tickets.msg.success'))
        }
        loading_submitstate.value = false

        setTimeout(()=>{ loadticketsdata() },500)
    }).catch(err=>{
        gconfig.$axiosErrorHandle(err)
    })
}
const loadticketsdata = () => {
    loading_tickets.value = true
    ticketsdata.value.splice(0x00,ticketsdata.value.length)
    ticketsdata.value.push({
        tickno: '-',
        title: '-',
        state: 0,
        statext: '-',
        stacolor: '-',
        createtime: '-',
        lasttime: '-',
        opts: '-'
    })
    axios.get(`${gconfig.$backendbase}/order/v3/getorders`,{
        params: { r:Math.random()},
        headers: gconfig.$getauthheaders()
    }).then(resp=>{
        //console.log(resp)
        ticketsdata.value.splice(0x00,ticketsdata.value.length)
        if(resp.data.data.result) {
            for(var i = 0x00; i < resp.data.data.orders.length; i++){
                var __order = resp.data.data.orders[i];
                ticketsdata.value.push({
                    tickno: __order.orderid,
                    title: decodeURIComponent(__order.title),
                    state: __order.state,
                    statext: __order.state == 0x00 || __order.state == 0x01 ? 
                        gconfig.$langsmgr('langkey.component.tickets.text.state.talking') : 
                        gconfig.$langsmgr('langkey.component.tickets.text.state.closed'),
                    stacolor: __order.state == 0x00 || __order.state == 0x01 ? 'green' : 'blue',
                    createtime: __order.pubtime,
                    lasttime: __order.lasttime,
                    opts: '-'
                })
            }
            
        }
        loading_tickets.value = false
    }).catch(err=>{
        gconfig.$axiosErrorHandle(err)
    })
}
const closeticket = ticket => {
    modal.confirm({
        title: gconfig.$langsmgr('langkey.component.tickets.cfm.title'),
        icon: h(ExclamationCircleOutlined),
        content: gconfig.$langsmgr('langkey.component.tickets.cfm.desc'),
        okText: gconfig.$langsmgr('langkey.component.tickets.cfm.ok'),
        okType: "primary danger",
        cancelText: gconfig.$langsmgr('langkey.component.tickets.cfm.cancel'),
        onOk() {
            //console.log(ticket)
            axios.get(`${gconfig.$backendbase}/order/v3/closeticket`,{
                params: {
                    ticketid: ticket.tickno,
                    r: Math.random()
                },
                headers: gconfig.$getauthheaders()
            }).then(resp=>{
                //console.log(resp)
                if(resp.data.data){
                    ticket.state = 0x02
                    message.success(gconfig.$langsmgr('langkey.component.tickets.msg.close.success'))
                }
            }).catch(err=>{
                gconfig.$axiosErrorHandle(err)
            })
        }
    })
}

</script>

<style src="./../assets/common.css" scoped></style>
<style scoped>
.feildtitle{
    padding-top: 10px;
    color:#666666;
    line-height:40px;
    font-weight: bold;
}
.noopts{
    color:#999999;
}
</style>